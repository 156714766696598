import { Routes, Route } from "react-router-dom";

import { ThemeContext } from "../../contexts/theme-context";

import Home from "../Home";
import NotFound from "../NotFound";

const App = () => {
  return (
    <ThemeContext.Consumer>
      {({ theme }) => (
        <Routes>
          <Route path="/" element={<Home theme={theme} />} />
          <Route path="*" element={<NotFound theme={theme} />} />
        </Routes>
      )}
    </ThemeContext.Consumer>
  );
};

export default App;
