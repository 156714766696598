import { useState } from "react";

import { ThemeContext } from "../../contexts/theme-context";
import Routes from "../Routes";

import "./App.scss";

const App = () => {
  const getCurrentTheme = () => {
    // if user previously interactedd with the theme toggle,
    // user the last preference, otherwise, use the browser preference
    const localStorageSelectedTheme = localStorage.getItem("selectedTheme");
    const browserPreference = window.matchMedia("(prefers-color-scheme: dark)")
      .matches
      ? "dark"
      : "light";
    return localStorageSelectedTheme || browserPreference;
  };

  const [theme, setTheme] = useState(getCurrentTheme() || "dark");

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <div className={`App-Wrapper App-Wrapper--${theme}`}>
        <div className="App">
          <Routes />
        </div>
      </div>
    </ThemeContext.Provider>
  );
};

export default App;
