import { useContext } from "react";
import { ThemeContext } from "../../contexts/theme-context";

import "./HoustonBreedlove.scss";

const HoustonBreedlove = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <h1 className={`HoustonBreedlove HoustonBreedlove--${theme}`}>
      Houston Breedlove
    </h1>
  );
};

export default HoustonBreedlove;
