import { useContext } from "react";

import { ThemeContext } from "../../contexts/theme-context";
import ThemeToggle from "../ThemeToggle";

import "./NavBar.scss";


const NavBar = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <nav className={`NavBar-Nav NavBar-Nav--${theme}`}>
      <ThemeToggle />
    </nav>
  );
};

export default NavBar;
