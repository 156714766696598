import { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";

import { ThemeContext } from "../../contexts/theme-context";

import "./ThemeToggle.scss";

const Header = () => {
  const { theme, setTheme } = useContext(ThemeContext);
  const [isCurrentDark, setIsCurrentDark] = useState(theme === "dark");

  const handleThemeChange = () => {
    const newTheme = isCurrentDark ? "light" : "dark";
    setTheme(newTheme);
    setIsCurrentDark(!isCurrentDark);
    localStorage.setItem("selectedTheme", newTheme);
  };

  return (
    <div className="ThemeToggle-Wrapper">
      <label className="ThemeToggle-Label">
        <input
          type="checkbox"
          checked={isCurrentDark}
          className="ThemeToggle-Checkbox"
          id="checkbox"
          onChange={handleThemeChange}
        />
        <FontAwesomeIcon className="ThemeToggle-Moon" icon={faMoon} size="xs" />
        <FontAwesomeIcon className="ThemeToggle-Sun" icon={faSun} size="xs" />
        <div
          className={
            !isCurrentDark
              ? "ThemeToggle-Ball"
              : "ThemeToggle-Ball ThemeToggle-Ball--isChecked"
          }
        />
      </label>
    </div>
  );
};

export default Header;
